<template>
  <footer class="footer min-w-1400 max-w-1400">
    <div class="footer-content">
      <!-- <p class="firstLine">
    กำกับดูแลโดยสำนักงานคณะกรรมการการธนาคารแห่งชาติและสำนักงานคณะกรรมการกำกับหลักทรัพย์
      </p> -->
      <p class="secondLine">Copyright © LESTAR GROUP JOINT STOCK COMPANY</p>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: #165FED;
  color: white;
  height: 80px;

}

.footer-content {
  font-size: 22px;
  text-align: center;
  margin: 0 auto;
  font-family: SF Pro, SF Pro;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
}
.firstLine {
  width: 1270px;
  height: 20px;
  font-size: 24px;
  font-family: SF Pro, SF Pro;
  font-weight: 400;
  color: rgba(255, 255, 255,0.9); 
  line-height: 28px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
}
.secondLine {
  width: 1098px;
  height: 20px;
  font-size: 20px;
  font-family: SF Pro-Regular;
  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  /* margin-top: 22px; */
}
</style>
